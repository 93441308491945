/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 79번째 로그에서는 개발자를 위한 iMac, 개발 이슈 관리 도구, JXA(JavaScript for Automation) 등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "개발자를 위한 iMac"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=m13hIewNVyU&t=23s"
  }, "누가 요즘 맥북프로 써? (feat. 아이맥) - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://store.hp.com/us/en/pdp/hp-prodesk-400-g5-mini-desktop-pc-customizable-6ge68av-1"
  }, "HP ProDesk 400 G5 Mini Desktop PC - Customizable (6GE68AV_1)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.keychron.com/products/keychron-k2-wireless-mechanical-keyboard"
  }, "Keychron K2 Wireless Mechanical Keyboard")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=NKEdTOVR3PM&feature=youtu.be"
  }, "바밀로 저소음 적축키보드 VA87M Orange and White - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/shop/product/MLA22LL/A/magic-keyboard-us-english"
  }, "Magic Keyboard - US English - Apple")), "\n"), "\n", React.createElement(_components.h3, null, "개발자 이슈 관리 도구"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/MichaelMure/git-bug"
  }, "MichaelMure/git-bug: Distributed, offline-first bug tracker embedded in git, with bridges")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://asana.com/"
  }, "Manage your team’s work, projects, & tasks online · Asana")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.ciokorea.com/t/27240/%EB%8D%B0%EB%B8%8C%EC%98%B5%EC%8A%A4/130570"
  }, "아틀라시안, 지라·컨플루언스에 '무료 등급' 신설 - CIO Korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.atlassian.com/blog/announcements/atlassian-cloud-remote-friendly-tools-free-small-teams"
  }, "We're making our remote-friendly software products available for free - Work Life by Atlassian")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.blog/changelog/2019-11-12-notifications-beta/"
  }, "Notifications (beta) - The GitHub Blog")), "\n"), "\n", React.createElement(_components.h3, null, "JXA(JavaScript for Automation)"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%95%A0%ED%94%8C%EC%8A%A4%ED%81%AC%EB%A6%BD%ED%8A%B8"
  }, "애플스크립트 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gist.github.com/JMichaelTX/d29adaa18088572ce6d4"
  }, "JavaScript for Automation (JXA) Resources")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.devontechnologies.com/apps/devonthink/new"
  }, "DEVONtechnologies | New in DEVONthink 3")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://support.apple.com/ko-kr/guide/automator/welcome/mac"
  }, "Mac용 Automator 사용 설명서 - Apple 지원")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.oliver-matuschin.de/en/projects/task-till-dawn"
  }, "Task Till Dawn · Projects | oliver-matuschin.de")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/JXA-Cookbook/JXA-Cookbook"
  }, "JXA-Cookbook/JXA-Cookbook: Cookbook for JavaScript for Automation in Mac OS X Yosemite")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.alfredapp.com/"
  }, "Alfred - Productivity App for macOS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.keyboardmaestro.com/main/"
  }, "Keyboard Maestro 9.0.5: Work Faster with Macros for macOS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developer.apple.com/design/human-interface-guidelines/macos/extensions/services/"
  }, "Services - Extensions - macOS - Human Interface Guidelines - Apple Developer")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bear.app/"
  }, "Bear - Notes for iPhone, iPad and Mac")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
